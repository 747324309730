<template>
  <div class="login-view">
    <v-layout align-center fill-height class="login-view__layout">
      <v-flex v-if="!$root.isMobile" lg5 class="login-view__layout-col">
        <div class="login-view__decoration">
          <v-img src="@/assets/img/injured-athlete-leg.jpg" position="cover" height="100%"></v-img>
        </div>
      </v-flex>
      <v-flex grow class="login-view__layout-col login-view__layout-col--primary">
        <v-layout align-center justify-center class="login-view__content">
          <div class="login-view__form-wrapper">
            <div class="login-view__form-header">
              <FormTitle>Sign in</FormTitle>
            </div>
            <div class="login-view__form-body">
              <LoginForm
                @submit="handleLoginFormSubmit"
                :externalFormErrors="externalErrors.errors.loginForm"
                :loading="loadingLoginForm"
                :login.sync="loginForm.email"
                :password.sync="loginForm.password"
                :forgotPasswordRoute="{ name: 'forgotPassword' }"
              />
            </div>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { externalErrors } from '@/mixins';
import { checkErrorsHaveCode } from '@/utils/helpers/graphql';
import FormTitle from '@/components/typography/FormTitle';
import LoginForm from '@/components/forms/LoginForm';

export default {
  name: 'Login',
  mixins: [externalErrors],
  components: {
    FormTitle,
    LoginForm,
  },
  data() {
    return {
      loadingLoginForm: false,
      loginForm: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapGetters('user', ['homeRoute']),
  },
  methods: {
    ...mapActions('user', ['authUser', 'changePasswordComplete']),
    ...mapActions('captcha', ['executeAction']),
    async handleLoginFormSubmit() {
      try {
        this.loadingLoginForm = true;
        await this.executeAction('login');
        const authed = await this.authUser({ ...this.loginForm });
        if (authed) {
          const {
            query: { redirect },
          } = this.$route;
          this.$router.push(redirect ? { path: redirect } : { name: this.homeRoute });
          return authed;
        }
        this.loadingLoginForm = false;
        throw new Error('Error while authorizing');
      } catch (error) {
        this.loadingLoginForm = false;
        if (checkErrorsHaveCode(error, 403)) {
          this.setExternalError('loginForm', {
            password: 'You did not sign in correctly or your account is temporarily disabled',
          });
        } else {
          this.$dialog.error({ title: 'Authorization error', error });
        }
        console.error(error);
        return error;
      }
    },
  },
  created() {
    this.initExternalErrors('loginForm', this.loginForm);
  },
};
</script>

<style lang="scss">
.login-view {
  height: 100%;
}
.login-view__layout-col {
  height: 100%;
}
.login-view__decoration {
  height: 100%;
}
.login-view__content {
  height: 100%;
  padding: 2rem;
}
.login-view__form-wrapper {
  min-width: 360px;
}
.login-view__form-header {
  margin-bottom: 2.75rem;
}
</style>
