<template>
  <v-form @submit.prevent="submit" ref="form" class="login-form">
    <div class="login-form__content">
      <div class="login-form__fields">
        <v-text-field
          @input="handleLoginInput"
          :value="login"
          :label="loginLabel"
          :rules="['required', 'email']"
        />
        <v-text-field
          @input="handlePasswordInput"
          :value="password"
          :error="!!externalFormErrors.password"
          :error-messages="externalFormErrors.password"
          :label="passwordLabel"
          :rules="['required']"
          type="password"
        />
      </div>
      <div v-if="forgotPasswordRoute" class="login-form__forgot-password-wrapper text-right">
        <router-link :to="forgotPasswordRoute" class="login-form__forgot-password-link">
          Forgot password?
        </router-link>
      </div>
      <div class="login-form__footer">
        <v-btn :block="!$root.isMobile" :loading="loading" type="submit">
          Sign in
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import { externalFormErrors, syncForm, validateForm } from '@/mixins';

export default {
  name: 'LoginForm',
  mixins: [externalFormErrors, syncForm, validateForm],
  props: {
    forgotPasswordRoute: [Object, String],
    loading: Boolean,
    login: String,
    loginLabel: {
      type: String,
      default: 'Email',
    },
    password: String,
    passwordLabel: {
      type: String,
      default: 'Password',
    },
  },
  methods: {
    handleLoginInput(value) {
      const field = 'login';
      this.$emit(`update:${field}`, value);
      this.handleFieldInput(field, value);
    },
    handlePasswordInput(value) {
      const field = 'password';
      this.handleFieldInput(field, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form__footer {
  margin-top: 1.125rem;
}
</style>
