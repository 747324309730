export default (error, errorCode) => {
  if (
    error instanceof Object &&
    error.graphQLErrors instanceof Array &&
    error.graphQLErrors.length
  ) {
    return error.graphQLErrors.some(err => {
      if (err.message instanceof Object) {
        const { statusCode } = err.message;
        return statusCode === errorCode;
      }
      return false;
    });
  }
  return false;
};
